import React from "react";
import Layout from "../components/App/Layout";
import Navbar from "../components/App/Navbar";
import PageBanner from "../components/Common/PageBanner";
import Footer from "../components/App/Footer";
import { Helmet } from "react-helmet";

const PrivacyPolicy = ({ location }) => {
  return (
    <Layout>
      <Helmet>
        <title>Privacy Policy | HydraChain</title>
        <link rel="canonical" href={`https://hydrachain.org/${location.pathname}`} />
      </Helmet>
      <Navbar />
      <PageBanner pageTitle="Privacy Policy" homePageText="Home" homePageUrl="/" activePageText="Privacy Policy" />
      <section className="privacy-policy-area ptb-100">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="privacy-policy-content">
                <h2>Privacy Policy for "LockTrip" LLC.</h2>
                <br />
                <p dir="ltr">At LockTrip, accessible from https://locktrip.com/, one of our main priorities is the privacy of our visitors. This Privacy Policy document contains types of information that is collected and recorded by LockTrip and how we use it.</p>
                <p dir="ltr">This policy applies to all Processing of Personal Data in electronic form (including electronic mail) or where it is held in manual files that are structured in a way that allows ready access to information about individuals. </p>
                <p dir="ltr">
                  This policy has been designed to establish a common framework for the processing and protection of Personal Data by "LockTrip" LLC. If an EU or a local national law imposes a requirement which is stricter than those imposed by this policy, the requirements in the EU and the
                  national law must be followed. Furthermore, where an EU or a national law imposes a requirement that is not addressed in this policy, the relevant EU or national law must be adhered to. If you have additional questions or require more information about our Privacy Policy, do not
                  hesitate to contact us through email at team@locktrip.com
                </p>
                <br />
                <h3>General Data Protection Regulation (GDPR)</h3>
                <p dir="ltr">We are a Data Controller of your information. Data Controller under this Policy shall mean a natural or legal person, public authority, agency, or other body which, alone or jointly with others, determines the purposes and means of the Processing of Personal Data. </p>
                <p dir="ltr">
                  Personal Data shall be processed lawfully, fairly and in a transparent manner in relation to the Data Subject. This means that, where legally required, LockTrip must tell the Data Subject what Processing will occur, i.e. LockTrip must ensure that the individuals are provided with a
                  privacy information notice concerning the processing of their Personal Data (transparency).
                </p>
                <p dir="ltr">
                  <b>Whose personal data LockTrip collects and processes?</b>
                </p>
                <p dir="ltr">In the course of its activity Lock Trip collects and processes personal data pertaining to:</p>
                <p dir="ltr">• Website visitors/users as well as visitors of our social media profiles</p>
                <p dir="ltr">• Clients</p>
                <p dir="ltr">As clients we refer to both individual clients, as well as to legal representatives/ employees of a corporate client, including where our business relationship are established via our website.</p>
                <p dir="ltr">
                  For the purposes of the activities of LockTrip as regards our obligations to prevent money laundering, terrorist financing, drug and human trafficking, proliferation of weapons of mass destruction, corruption and bribery and to take action in case of any form of suspicious activity
                  from its Users, along with data concerning client’s pieces of personal data pertaining to other persons may be collected.
                </p>
                <p dir="ltr">These may be individuals who are the beneficial owners of a corporate client and/or individuals who are the legal representative of entities.</p>
                <p dir="ltr" align="center">
                  <b>Website visitors</b>
                </p>
                <p dir="ltr">
                  <b>What personal data we collect? How do we obtain them and why do we process them?</b>
                </p>
                <p dir="ltr">Personal information LockTrip collects when you visit our website may include information you provide voluntarily as well as information collected automatically.</p>
                <p dir="ltr">
                  LockTrip collects personal data that you provide voluntarily via our website, such as when filling in online forms/ applications/ inquiries/ complaints or when you use our email to reach us. This information may include name, positions and company (employer), contact details such
                  as email and phone number, as well as other personal information, which you freely decided to disclose. LockTrip also collects these data you subscribe for receiving of bulletin or other marketing communication from us.
                </p>
                <p dir="ltr">
                  When you visit our website, we automatically collect particular personal details. This information collected automatically may include your IP address, device type, device unique identification number, browser type, geo-location, webpages visited, time and date of visit as well as
                  other technical information. Collecting this type of information allows us to better understand our website visitors, where they come from and which elements of our web content is of interest to them. We use this information for internal analytics purposes and for improvement of
                  the quality and suitability of our website in terms of you visitors’ preferences.
                </p>
                <p dir="ltr">Some of the data we collect by the use of cookies and other similar technology. More information in this regard may be found in our Cookies Policy.</p>
                <p dir="ltr">
                  Personal data about you may also be collected and processed when you visit our pages/profiles in social media, including when you use these to contact use. We also receive data on you adding our page to favourites, like-ing our published content, as well as data about the total
                  number of visitors and other similar details concerning your activity in social media. All this data we receive from the company that owns the platform. Please bear in mind that LockTrip is not responsible for the governance of social media or their data collection and processing
                  practices, including for the use of cookies, analytics, web beacons and other similar technology. Should you prefer of deem it necessary, you may exercise your rights concerning data protection towards the providing company.
                </p>
                <p dir="ltr" align="center">
                  <b>Clients</b>
                </p>
                <p dir="ltr">
                  <b>Personal information we collect for our clients</b>
                </p>
                <p dir="ltr">In the course of its business activity, LockTrip collects personal data for its clients, including:</p>
                <ul>
                  <li dir="ltr">
                    <p dir="ltr">Full name</p>
                  </li>
                  <li dir="ltr">
                    <p dir="ltr">Personal Identification Number or Personal Number of Foreigner</p>
                  </li>
                  <li dir="ltr">
                    <p dir="ltr">any citizenship acquired; </p>
                  </li>
                  <li dir="ltr">
                    <p dir="ltr">country of residence and main address </p>
                  </li>
                  <li dir="ltr">
                    <p dir="ltr">Identity card / passport or other official identity document, the period of validity of which has not expired and has a photo of the client</p>
                  </li>
                  <li dir="ltr">
                    <p dir="ltr">Photo of the client</p>
                  </li>
                  <li dir="ltr">
                    <p dir="ltr">Contact details, such as address, e-mail, phone number</p>
                  </li>
                  <li dir="ltr">
                    <p dir="ltr">Bank details, such as credit/debit card details, IBAN or other payment account number</p>
                  </li>
                  <li dir="ltr">
                    <p dir="ltr">Sensitive personal data such as political views only on the KYC/AML process.</p>
                  </li>
                </ul>
                <p dir="ltr">
                  As mentioned before, LockTrip must be maximally protected that its activity is not used for money laundering and terrorist financing. In accordance with the KYC process, LockTrip will identify you as a client and whereas the client is an entity, LockTrip shall identify its
                  beneficial owners.
                </p>
                <p dir="ltr">In connection with the above identification, LockTrip will collect a copy of the presented identity document.</p>
                <p dir="ltr">LockTrip may gather data about the professional and business activity of the client as well as other data in accordance with the requirements of the law to prevent money laundering and terrorist financing.</p>
                <p dir="ltr">
                  <b>Special Categories of Data </b>
                </p>
                <p dir="ltr">
                  Special Categories of Personal Data means pertaining to or revealing racial or ethnic origin, political opinions, religious or philosophical beliefs, trade-union membership; data concerning health or sex life and sexual orientation; genetic data or biometric data for the purpose of
                  uniquely identifying a natural person.
                </p>
                <p dir="ltr">
                  In connection with the application of specific AML measures related to PEPs or close relatives LockTrip may collect information about political views of its clients with a declaration. Some of the information collected by this declaration may be considered as Special Categories of
                  Data (also known as sensitive data). LockTrip will only processes such kind of information where the Data Subject expressly consents to such Processing or where one of the following conditions apply:{" "}
                </p>
                <p dir="ltr">• The Processing relates to Personal Data which has already been made public by the Data Subject. </p>
                <p dir="ltr">• The Processing is necessary for the establishment, exercise or defence of legal claims; </p>
                <p dir="ltr">• The Processing is specifically authorised or required by law (e.g. for Employees this would be the case where medical information about them should be provided to the Company in relation to sick leaves); </p>
                <p dir="ltr">• The Processing is necessary to protect the vital interests of the Data Subject or of another natural person where the Data Subject is physically or legally incapable of giving consent; </p>
                <p dir="ltr">• Further conditions, including limitations, based upon applicable national law related to the Processing of genetic data, biometric data or data concerning health. </p>
                <br />
                <p dir="ltr">Where Special Categories of Data are being Processed, LockTrip will adopt additional protection measures. LockTrip may also adopt additional measures to address local custom or social expectation over the Processing of Special Categories of Data.</p>
                <p dir="ltr">How do we collect the information?</p>
                <p dir="ltr">In the course of exercising our business activity, LockTrip collects personal data via the following channels:</p>
                <p dir="ltr">• Directly from you – when you send us requests, register on our website, submit forms and documents for participation in different initiatives.</p>
                <p dir="ltr">• Publicly available sources – data from Commercial Register, including published financial statements, Register of Non-profit Legal Entities, professional profile online, information published in the media.</p>
                <br />
                <p dir="ltr">
                  <b>Why do we use your personal data?</b>
                </p>
                <p dir="ltr">
                  We use your personal data in the course of the performance of our activities upon establishment of relations with potential and current clients, for establishment of our position at the market and others. In particular, we process your personal data for the following:
                </p>
                <p dir="ltr">• Drafting of contract offers and conclusion of contracts</p>
                <p dir="ltr">• Administration, management and performance of clients’ request or orders concerning our products or services, performance of contracts for products or services</p>
                <ul>
                  <li dir="ltr">
                    <p dir="ltr">Processing is necessary for the purposes of the legitimate interests pursued by LockTrip to prevent its activity to be used for money laundering or terrorist financing</p>
                  </li>
                </ul>
                <p dir="ltr">• Collection of sums</p>
                <p dir="ltr">• Performance of deliveries, client support, rendering technical assistance, including upon grievances or complaints from clients</p>
                <p dir="ltr">• Sending of marketing messages, brochures and other types of letters concerning the products and services of LockTrip</p>
                <br />
                <p dir="ltr">
                  <b>What else you need to know?</b>
                </p>
                <p dir="ltr">If you use our website for creating an account and for sending orders as well as when you use our contact form we will also process your personal data in your capacity as website visitor/user, including via electronic means upon loading of our webpage.</p>
                <br />
                <p dir="ltr">
                  <b>Legal Basis for processing of Personal Data</b>
                </p>
                <p dir="ltr">LockTrip Ltd. legal basis for collecting and using the personal information described in this Privacy Policy depends on the Personal Information we collect and the specific context in which we collect the information:</p>
                <div dir="ltr" align="left">
                  <table>
                    <colgroup>
                      <col width="150" />
                      <col width="297" />
                      <col width="204" />
                    </colgroup>
                    <tbody>
                      <tr>
                        <td>
                          <p dir="ltr">Personal Data</p>
                        </td>
                        <td>
                          <p dir="ltr">Purpose</p>
                        </td>
                        <td>
                          <p dir="ltr">Legal Basis</p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <ul>
                            <li dir="ltr">
                              <p dir="ltr">Full name</p>
                            </li>
                            <li dir="ltr">
                              <p dir="ltr">Personal Identification Number or Personal Number of Foreigner </p>
                            </li>
                            <li dir="ltr">
                              <p dir="ltr">Bank details, such as credit/debit card details, IBAN or other payment account number</p>
                            </li>
                          </ul>
                        </td>
                        <td>
                          <p dir="ltr">Providing service and answering inquiries, signals, complaints, troubleshooting and other feedback regarding the services provided.</p>
                        </td>
                        <td>
                          <p dir="ltr">Processing is necessary for the performance of a contract to which the data subject is party or in order to take steps at the request of the data subject prior to entering into a contract.</p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <ul>
                            <li dir="ltr">
                              <p dir="ltr">Any citizenship acquired </p>
                            </li>
                            <li dir="ltr">
                              <p dir="ltr">Country of residence and address Main Address </p>
                            </li>
                            <li dir="ltr">
                              <p dir="ltr">Identity card / passport or other official identity document, the period of validity of which has not expired and has a photo of the client</p>
                            </li>
                            <li dir="ltr">
                              <p dir="ltr">Photo of the client</p>
                            </li>
                            <li dir="ltr">
                              <p dir="ltr">Sensitive personal data such as political views only on the KYC/AML process</p>
                            </li>
                          </ul>
                        </td>
                        <td>
                          <p dir="ltr">Given the specific activity of LockTrip, it estimates that it can be used for abuses such as money laundering and terrorist financing.</p>
                          <p dir="ltr">To prevent such illegal activities, the company has set up a KYC/AML process.</p>
                          <p dir="ltr">In this regard, the company has an interest in concluding contracts only with persons who have been carefully inspected.</p>
                        </td>
                        <td>
                          <p dir="ltr">Processing is necessary for the purposes of the legitimate interests pursued by the controller to prevent its activity to be used for money laundering and terrorist financing.</p>
                          <p dir="ltr">The data subject has given consent to the processing of his or her personal data for these specific purposes.</p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p dir="ltr">Contact details, such as address, e-mail, phone number</p>
                          <br />
                        </td>
                        <td>
                          <p dir="ltr">Making contact </p>
                        </td>
                        <td>
                          <p dir="ltr">The data subject has given consent to the processing of his or her personal data for this specific purpose</p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <br />
                <p dir="ltr">
                  Your personal data will be processed by LockTrip only in accordance with the applicable data protection regulations. When you correspond with us through any of the communication channels, you confirm that the data you have provided is accurate, correct and up-to-date.
                </p>
                <p dir="ltr">
                  We should inform you that any consent to the processing of your personal data may be withdrawn at any time, as by submitting a written request to the address of the LockTrip in Sofia, Mladost district, 78 Alexander Malinov Blvd., and / or sending an e-mail to team@locktrip.com
                </p>
                <br />
                <p dir="ltr">
                  <b>For how long will my personal data be stored?</b>
                </p>
                <p dir="ltr">
                  LockChain Ltd. will retain your personal information only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use your information to the extent necessary to comply with our legal obligations, resolve disputes, and enforce our policies.
                  Our Privacy Policy was generated with the help of GDPR Privacy Policy Generator.
                </p>
                <p dir="ltr">Once the purposes for which the personal data have been collected have been achieved, we will destroy it immediately.</p>
                <p dir="ltr">
                  LockTrip takes all necessary technical and organizational measures for the destruction of data that are no longer needed, except in cases where there is a legal basis for LockTrip to process it for a longer period of time; upon your request to limit the processing, according to
                  your rights, described in detail below; or with a view to being compatible with the original purpose of the processing, of which you will be informed.
                </p>
                <p dir="ltr">LockTrip stores the collected personal data within the following terms:</p>
                <p dir="ltr">
                  a) when the data are processed on the basis of a concluded contract with clients - for a period of 5 years, which period starts from the date of termination of the contract with clients. Sensitive personal data collected in connection with customer identification will be stored for
                  the same period.
                </p>
                <p dir="ltr">b) when the data are processed on the basis of obtained consent - until the explicit withdrawal of the consent.</p>
                <p dir="ltr">c) when the data are processed to protect the realization of the rights and interests of the Company, which have a justified advantage over the interests of individuals - until the expiration of the right and / or loss of interest.</p>
                <p dir="ltr">d) where the data are processed for compliance with a legal obligation that applies to the controller, subject to legal provisions. </p>
                <p dir="ltr">
                  After the expiration of the specified terms, if there is no other reason for data processing, they will be deleted. In order to obtain and analyze information related to the products and services you use, as well as to improve the service, the Company may delete only part of the
                  data. In these cases, it continues to store part of the data that does not allow individuals to be subsequently identified.
                </p>
                <br />
                <p dir="ltr">
                  <b>Who will we share your personal information with?</b>
                </p>
                <p dir="ltr">We undertake to share your information only amongst employees of LockTrip who require your information to perform their job functions. All of the individuals are under duty of confidentiality in terms of further disclosure of your personal information.</p>
                <p dir="ltr">
                  Sometimes LockTrip need to share your personal information with our partner organizations. When this is necessary, e.g. for accessing the services of IT providers for development of our systems and technical maintenance; auditors and consultants, who assess the compliance of our
                  activity with various regulatory requirements; legal advisors securing our legal interests; successors of the Company or business partner in case all of part of the Company is sold, restructured or merged with another company, including in the course of due diligence processes for
                  this purposes and other cases, we will comply with all aspects of applicable European and national data protection legislation.
                </p>
                <p dir="ltr">Whenever necessary or required, we share information with:</p>
                <p dir="ltr">• IT services Providers</p>
                <p dir="ltr">• Equipment Providers</p>
                <p dir="ltr">• Legal advisors</p>
                <p dir="ltr">• Auditors and consultants</p>
                <p dir="ltr">• Banks</p>
                <p dir="ltr">• Insurance companies</p>
                <p dir="ltr">• Other service providers, e.g. corporate events organizers, couriers and others</p>
                <p dir="ltr">• Legal successor of the Company</p>
                <p dir="ltr">• Public authorities of the Republic of Bulgaria (in compliance with applicable legislation).</p>
                <br />
                <p dir="ltr">Do we transfer your data to countries outside the European Economic Area?</p>
                <p dir="ltr">We do not transfer your personal data to countries or territories outside the European Economic Area. </p>
                <p dir="ltr">
                  Any transfer of personal data which are undergoing processing or are intended for processing after transfer to a third country or to an international organisation shall take place only if, subject to the other provisions of this Regulation, the conditions laid down in Chapter V of
                  General Regulation on Personal Data Protection are complied with by the Controller, including for onward transfers of personal data from the third country or an international organisation to another third country or to another international organisation.{" "}
                </p>
                <br />
                <p dir="ltr">
                  <b>How do we protect your personal data?</b>
                </p>
                <p dir="ltr">To ensure adequate data protection of the Company and its Clients, we apply all necessary organizational and technical measures provided for in the Personal Data Protection Act and the General Regulation on Personal Data Protection.</p>
                <p dir="ltr">The company has established structures for the prevention of misuse and security breaches, and a Data Protection Officer has been appointed to support the processes of protection and security of your data.</p>
                <p dir="ltr">For maximum security in the processing, transmission and storage of your data, we may use additional security mechanisms such as encryption, pseudonymization and more.</p>
                <p dir="ltr">The Company stores your data on special server spaces, which are accessible only to employees of the Company who are directly engaged in the processing of your data.</p>
                <p dir="ltr">Paper copies are stored in special places, which are not publicly available or available to persons with unauthorized access.</p>
                <br />
                <p dir="ltr">
                  <b>Your rights</b>
                </p>
                <p dir="ltr">If you are a resident of the European Economic Area (EEA), you have certain data protection rights. If you wish to be informed what Personal Information we hold about you and if you want it to be removed from our systems, please contact us.</p>
                <p dir="ltr">In certain circumstances, you have the following data protection rights:</p>
                <ul>
                  <li dir="ltr">
                    <p dir="ltr">The right of information.</p>
                  </li>
                  <li dir="ltr">
                    <p dir="ltr">The right to access, update or to delete the information we have on you.</p>
                  </li>
                  <li dir="ltr">
                    <p dir="ltr">The right of rectification.</p>
                  </li>
                  <li dir="ltr">
                    <p dir="ltr">Right of erasure.</p>
                  </li>
                  <li dir="ltr">
                    <p dir="ltr">The right to object.</p>
                  </li>
                  <li dir="ltr">
                    <p dir="ltr">The right of restriction.</p>
                  </li>
                  <li dir="ltr">
                    <p dir="ltr">The right to data portability.</p>
                  </li>
                  <li dir="ltr">
                    <p dir="ltr">The right to withdraw consent.</p>
                  </li>
                  <li dir="ltr">
                    <p dir="ltr">Right to Complain before the Surveillance Authority.</p>
                  </li>
                </ul>
                <p dir="ltr">In more details</p>
                <p dir="ltr">
                  <b>Right of information:</b> You have the right to receive relevant information of our processing of your data, which is actually the purpose of this Policy. However, at any time you may contact us to receive additional information or clarifications on whether and how LockTrip
                  processes any of your personal data.
                </p>
                <p dir="ltr">
                  <b>Right of access:</b> You have the right to get free information about the nature, volume and way we use your personal data, and get a copy of this information. In addition, you have the right to receive information about the recipients of your data, including whether your
                  personal data is being transferred to a country or an international organization located outside the European Union. If such a transfer takes place, you have the right to be informed of the appropriate precaution measures taken to protect your personal information.
                </p>
                <p dir="ltr">
                  <b>Right of Rectification:</b> You may request from us within a reasonable time to supplement and / or correct incomplete, inaccurate or outdated personal data.
                </p>
                <p dir="ltr">
                  <b>Right of Erasure:</b> You may require the deletion of your personal data and we are obliged to delete the personal data without undue delay, unless there is a legitimate legally prescribed reason to deny your request, e.g. when the processing is mandatory under the applicable
                  legislation.
                </p>
                <p dir="ltr">
                  <b>Right of objection:</b> You may object to processing performed by LockTrip at any time on grounds relating to your particular situation (applicable when processing your data is done on the basis of a legitimate interest of LockTrip or a third party). In any case you may object
                  to processing for the purposes of direct marketing (if performed on this legal basis).
                </p>
                <p dir="ltr">
                  <b>Right to Restriction of Processing:</b> In the event that personal data that we process is inaccurate or obsolete or if you deem our processing unlawful, you have the right to ask us to limit / suspend the processing of such data for a period that allows us to verify the
                  accuracy of the information and correct it accordingly, or until we resolve the matter of the legality of our processing.
                </p>
                <p dir="ltr">
                  <b>Data portability:</b> This right allows you to get your personal data in a structured, machine-readable format. You are entitled to data portability inly where we process your personal information on the basis of consent or for the conclusion/performance of a contract and
                  provided that the information itself is processed via automated means.
                </p>
                <p dir="ltr">
                  <b>Right to withdraw consent:</b> When processing your personal data is based on your consent, you are entitled to withdraw it at any time. This can be done online by contacting us via email or in our office or via postal service at LockTrip address.
                </p>
                <p dir="ltr">Withdrawal of your consent does not affect the legality of the processing prior to the consent withdrawal.</p>
                <p dir="ltr">
                  <b>Right to Complain before the Surveillance Authority:</b> If you believe that your privacy and data protection rights have been violated, you have the right to contact the Bulgarian Data Protection Commission. CPDP data is as follows:
                </p>
                <p dir="ltr">Address: 2 Prof. Tsvetan Lazarov Blvd., Sofia 1592, Bulgaria </p>
                <p dir="ltr">
                  Е-mail: <a href="mailto:kzld@cpdp.bg">kzld@cpdp.bg</a>
                </p>
                <p dir="ltr">
                  Web-site: <a href="http://www.cpdp.bg/">www.cpdp.bg</a>
                </p>
                <br />
                <p dir="ltr">To exercise any of the above rights, you may contact us at any time. Before we can fulfil your request, we may ask for additional information to verify your identity.</p>
                <p dir="ltr">
                  <b>Log Files</b>
                </p>
                <p dir="ltr">
                  LockTrip follows a standard procedure of using log files. These files log visitors when they visit websites. All hosting companies do this and a part of hosting services' analytics. The information collected by log files include internet protocol (IP) addresses, browser type,
                  Internet Service Provider (ISP), date and time stamp, referring/exit pages, and possibly the number of clicks. These are not linked to any information that is personally identifiable. The purpose of the information is for analyzing trends, administering the site, tracking users'
                  movement on the website, and gathering demographic information.
                </p>
                <br />
                <p dir="ltr">
                  <b>Cookies and Web Beacons</b>
                </p>
                <p dir="ltr">
                  Like any other website, LockTrip uses 'cookies'. These cookies are used to store information including visitors' preferences, and the pages on the website that the visitor accessed or visited. The information is used to optimize the users' experience by customizing our web page
                  content based on visitors' browser type and/or other information.
                </p>
                <br />
                <p dir="ltr">
                  <b>Google DoubleClick DART Cookie</b>
                </p>
                <p dir="ltr">
                  Google is one of a third-party vendor on our site. It also uses cookies, known as DART cookies, to serve ads to our site visitors based upon their visit to www.website.com and other sites on the internet. However, visitors may choose to decline the use of DART cookies by visiting
                  the Google ad and content network Privacy Policy at the following URL – https://policies.google.com/technologies/ads
                </p>
                <br />
                <p dir="ltr">
                  <b>Privacy Policies</b>
                </p>
                <p dir="ltr">You may consult this list to find the Privacy Policy for each of the advertising partners of LockTrip.</p>
                <p dir="ltr">
                  Third-party ad servers or ad networks uses technologies like cookies, JavaScript, or Web Beacons that are used in their respective advertisements and links that appear on LockTrip, which are sent directly to users' browser. They automatically receive your IP address when this
                  occurs. These technologies are used to measure the effectiveness of their advertising campaigns and/or to personalize the advertising content that you see on websites that you visit.
                </p>
                <p dir="ltr">Note that LockTrip has no access to or control over these cookies that are used by third-party advertisers.</p>
                <br />
                <p dir="ltr">
                  <b>Third Party Privacy Policies</b>
                </p>
                <p dir="ltr">
                  Our website and services may provide links to other websites through direct links or through third party applications. LockTrip do not control these sites or their privacy practices, so we recommend that you familiarize yourself with their privacy practices before sharing your
                  personal data with these platforms.
                </p>
                <p dir="ltr">
                  LockTrip's Privacy Policy does not apply to other advertisers or websites. Thus, we are advising you to consult the respective Privacy Policies of these third-party ad servers for more detailed information. It may include their practices and instructions about how to opt-out of
                  certain options. You may find a complete list of these Privacy Policies and their links here: Privacy Policy Links.
                </p>
                <p dir="ltr">You can choose to disable cookies through your individual browser options. To know more detailed information about cookie management with specific web browsers, it can be found at the browsers' respective websites. What Are Cookies?</p>
                <p dir="ltr">
                  <b>Children's Information</b>
                </p>
                <p dir="ltr">Another part of our priority is adding protection for children while using the internet. We encourage parents and guardians to observe, participate in, and/or monitor and guide their online activity.</p>
                <p dir="ltr">
                  LockTrip does not knowingly collect any Personal Identifiable Information from children under the age of 13. If you think that your child provided this kind of information on our website, we strongly encourage you to contact us immediately and we will do our best efforts to
                  promptly remove such information from our records.
                </p>
                <p dir="ltr">
                  <b>Online Privacy Policy Only</b>
                </p>
                <p dir="ltr">
                  Our Privacy Policy applies only to our online activities and is valid for visitors to our website with regards to the information that they shared and/or collect in LockTrip. This policy is not applicable to any information collected offline or via channels other than this website.
                </p>
                <p dir="ltr">
                  <b>Consent</b>
                </p>
                <p dir="ltr">By using our website, you hereby consent to our Privacy Policy and agree to its terms.</p>
                <br />
                <p dir="ltr">
                  <b>Changes to this Policy</b>
                </p>
                <p dir="ltr">This Policy was last updated on 7th March 2021. We may change its contents by updating this page to reflect changes in applicable legislation or LockTrip privacy practices. However, we will not use your personal data in new ways without your knowledge or consent.</p>
                <br />
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </Layout>
  );
};

export default PrivacyPolicy;
